import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import favicon from "../../static/favicon.ico"
import appleTouchIcon from "../../static/apple-touch-icon.png"
import favicon32x32 from "../../static/favicon-32x32.png"
import favicon16x16 from "../../static/favicon-16x16.png"

const SEO = props => (
  <StaticQuery
    query={graphql`
      query DefaultSEOQuery {
        site {
          siteMetadata {
            title
            tagline
            author
          }
        }
      }
    `}
    render={data => {
      const title = props.title || data.site.siteMetadata.title
      return (
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
          title={title}
          titleTemplate={`%s - Award-winning Family Games`}
          link={[
            { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
            {
              rel: "apple-touch-icon",
              sizes: "180x180",
              href: `${appleTouchIcon}`,
            },
            {
              rel: "icon",
              type: "image/png",
              sizes: "32x32",
              href: `${favicon32x32}`,
            },
            {
              rel: "icon",
              type: "image/png",
              sizes: "16x16",
              href: `${favicon16x16}`,
            },
          ]}
        />
      )
    }}
  />
)

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: [],
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        tagline
        author
      }
    }
  }
`
