import { config } from "@fortawesome/fontawesome-svg-core"
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faCopyright, faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import dayjs from "dayjs"
import { initializeApp } from "firebase/app"
import { getDatabase, ref, set } from "firebase/database"
import { Link as GatsbyLink, navigate } from "gatsby"
import React from "react"
import { Container, Nav, Navbar } from "react-bootstrap"
import { Link } from "react-scroll"
import { ToastContainer } from "react-toastify"

import { loadPlayerId } from "../../utils"
import SEO from "../SEO"
import styles from "./style.module.scss"

import "../../styles/style.scss"
import "react-toastify/dist/ReactToastify.css"

config.autoAddCss = false

const Layout = ({ children, location, fluid, noFooter }) => {

  const firebaseConfig = {
    apiKey: "apiKey",
    authDomain: "alphabet-runner-game.firebaseapp.com",
    databaseURL:
      "https://alphabet-runner-game-default-rtdb.europe-west1.firebasedatabase.app/",
    storageBucket: "bucket.appspot.com",
  }

  const app = initializeApp(firebaseConfig)
  const database = getDatabase(app)

  function renderBreadcrumb() {
    const parts = location.pathname.split("/")
    parts.shift()
    parts.pop()
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {parts.map((part, i) => (
            <li
              key={i}
              className={cx("breadcrumb-item", {
                active: i === parts.length - 1,
              })}
            >
              {i !== parts.length - 1 ? (
                <Link
                  replace
                  to={`/${part}/${parts[i + 1].replace(" & ", "&")}`}
                >
                  <FontAwesomeIcon className="mr-2" icon={faLongArrowAltLeft} />
                  {part.replace("&", " & ")}
                </Link>
              ) : (
                <a href="#">{part.replace("&", " & ")}</a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    )
  }

  function handleBackHome() {
    const scorePayload = JSON.parse(localStorage.getItem('score'))
    if (scorePayload?.time?.seconds) {
      if (window.confirm('Click OK to leave the game or Cancel to continue. Your current time will still be submitted to the scoreboard.')) {
        const existingPlayerId = loadPlayerId()
        scorePayload.end = `${dayjs().format()}`
        scorePayload.incomplete = true
        set(
          ref(database, `/level-${scorePayload.level}-${existingPlayerId}`),
          scorePayload
        ).then(response => {
          return response
        })
        navigate('/')
      }
    } else {
      navigate('/')
    }
  }

  return (
    <>
      <Navbar sticky="top" className={cx(styles.navbar)}>
        <Container fluid={fluid || "xl"}>
          <Navbar.Brand href="#home">
            <Link
              // style={{ fontSize: "12px" }}
              to="hero"
              spy={true}
              smooth={true}
              offset={-200}
            >
              Alphabet Runner
            </Link>
          </Navbar.Brand>

          <Nav className={cx(styles.navItems, "ml-auto")}>
            {location.pathname === "/" && (
              <>
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="how-to-play"
                  spy={true}
                  smooth={true}
                  offset={-50}
                >
                  Guide
                </Link>
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="buy"
                  spy={true}
                  smooth={true}
                  offset={-50}
                >
                  Buy
                </Link>
                <GatsbyLink
                  className="nav-link"
                  activeClass="active"
                  to="/play"
                >
                  Play
                </GatsbyLink>
                <Link
                  className="nav-link"
                  activeClass="active"
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-50}
                >
                  Contact
                </Link>
              </>
            )}
            {location.pathname.includes("play") && (
              <button onClick={handleBackHome} className="btn btn-outline btn-default">
                Back to home
              </button>
            )}

            <Nav.Link
              target="_blank"
              href="https://mobile.twitter.com/alphabet_runner"
            >
              <FontAwesomeIcon style={{ color: "#1DA1F2" }} icon={faTwitter} />
            </Nav.Link>
            <Nav.Link
              target="_blank"
              href="https://www.instagram.com/alphabet.runner/"
            >
              <FontAwesomeIcon
                style={{ color: "#F56040" }}
                icon={faInstagram}
              />
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <SEO />
      <ToastContainer position="bottom-right" toa />
      <div
        className={cx({
          "container-lg": !fluid,
          "container-fluid": fluid,
        })}
      >
        {/* {location && (
          <div className="row">
            <div className="col">{renderBreadcrumb()}</div>
          </div>
        )} */}
        <div className="row no-gutters">
          <div className={cx("col", { "p-2 p-lg-5": !fluid })}>{children}</div>
        </div>
        {(noFooter !== true || noFooter === undefined) && (
          <div className="row mt-5">
            <div className="col">
              <footer className="mt-auto text-dark text-center">
                <div className="row ">
                  <Nav
                    className="justify-content-center w-100"
                    activeKey="/home"
                  >
                    <Nav.Item>
                      <Link
                        className="nav-link"
                        activeClass="active"
                        to="hero"
                        spy={true}
                        smooth={true}
                        offset={-200}
                      >
                        Back to top
                      </Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                    <Nav.Link eventKey="link-1">Privacy Policy</Nav.Link>
                  </Nav.Item> */}
                  </Nav>
                </div>
                <div className="my-5 text-muted">
                  Copyright <FontAwesomeIcon icon={faCopyright} /> 2005 - 2021
                  Alphabet Runner. All Rights Reserved.
                </div>
              </footer>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Layout
