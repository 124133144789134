function formatCategorySlug(title) {
  return title.replace(/ +?/g, "").toLowerCase()
}

const groupBy = keys => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = keys.map(key => obj[key]).join("-")
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const loadPlayerId = () => {
  try {
    const serializedState = localStorage.getItem("alphabet-game-uuid")
    if (serializedState === null) {
      return undefined
    }
    return serializedState
  } catch (err) {
    return undefined
  }
}

const savePlayerId = state => {
  try {
    localStorage.setItem("alphabet-game-uuid", state)
  } catch (err) {
    throw err
  }
}

const loadPlayerName = () => {
  try {
    const serializedState = localStorage.getItem("alphabet-game-name")
    if (serializedState === null) {
      return undefined
    }
    return serializedState
  } catch (err) {
    return undefined
  }
}

const savePlayerName = state => {
  try {
    localStorage.setItem("alphabet-game-name", state)
  } catch (err) {
    throw err
  }
}

module.exports = {
  formatCategorySlug,
  groupBy,
  loadPlayerId,
  savePlayerId,
  savePlayerName,
  loadPlayerName,
}
